.footer-container{
    background-color:#141414;
    height: 610px;
    display: grid;
    grid-template-rows: 280px  330px;
    font-family: "Montserrat", sans-serif;


}
.footer-container-details-row{
    /* background-color: rgb(19, 156, 154); */

    /* margin: 0 52px 0 52px; */
    border-bottom: 1px  #313131 solid;
}
.footer-container-details{
    /* background-color: red; */
    height: 178px;
    
}
.footer-details-row{
    /* background-color: rgb(222, 204, 4); */
    height: 168px;
    display: grid;
    grid-template-columns: 320px  1fr;
    column-gap: 65px;


}
.footer-company-details{
    /* background-color: rgb(208, 137, 50); */
    height: 50px;
   
}
.footer-comapany-logo{
    height: 48px;
    width: 251px;
    /* background-color: darkolivegreen; */
    display: flex;
    align-items: center;
 
}
.footer-comapany-logo-image img{
    width: 36px;
    height: 36px;
} 
.footer-comapany-logo h5{
    font-family: "Montserrat", sans-serif;
   font-weight: 500;
   font-size: 16px;

 
  
   /* text-align: center; */
   color: #ffff;

}
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@100..900&family=Lobster&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.footer-company-details h2{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #EAECF0;
    padding: 20px 0 0 0;


}
.footer-company-link{
    /* background-color: rgb(181, 110, 60); */
    display: grid;
    grid-template-columns: 1fr  1fr 1fr;
    column-gap: 26px;
  

    
}
.footer-company-link-comapny{
    /* background-color: blue; */
}
.footer-company-link-comapny-details{
 height: 56px;
 /* background-color: rgb(235, 159, 67); */
 margin:16px 0 0 0  ;
 display: grid;
 grid-template-rows: 1fr  1fr ;
 row-gap: 12px;

}
.footer-company-link-comapny-details h6{
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #EAECF0;
}




.footer-company-link-social{
    /* background-color: rgb(10, 170, 77); */
}
.footer-company-link-legal{
    /* background-color:rgb(26, 184, 6) ; */
}
.footer-company-link-comapny-legal{
    height: 132px;
    /* background-color: rgb(235, 159, 67); */
    margin:16px 0 0 0  ;
    display: grid;
    grid-template-rows: 1fr  1fr 1fr 1fr;
    row-gap: 12px;
}
.footer-company-link-comapny-legal h6{
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #EAECF0;
}
.footer-copyright-div{
    height: 30px;
    /* background-color: aquamarine; */
    padding: 38px 30px 38px 30px;
}
.footer-copyright-div div{
    height: 24px;
    /* background-color: #6edc20; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0px 0 0;
   

}
.footer-copyright-div div h5{
    font-weight: 700;
    margin: 0;
    padding: 0 0 0 66px;
    font-weight: 400;
    font-size: 16px;
    color:  #98A2B3;
}
.footer-social-media-div{
    width: 120px;
    /* background-color: #250ebb; */
    height: 24px;
    display: flex;
    padding: 0;
    
   
}
.footer-social-media-div img{
    width: 24px;
    height: 24px;
}
.footer-copyright-div h1 {
    margin: 120px 0 0 0;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.07);
}

/* md */
@media (max-width:767px){

    .footer-container{
        /* background-color:#b15151; */
        height: 610px;
        display: grid;
        grid-template-rows: 310px  330px;
        font-family: "Montserrat", sans-serif;
    
    
    }
    .footer-company-link{
        /* background-color: #98A2B3; */
        padding-top: 10px;
        grid-template-columns: 1fr;
        
       
    }

    .footer-container-details{
        /* background-color: red; */
        height: auto;
        
    }

    .footer-details-row{
        /* background-color: rgb(19, 222, 4); */
        height: auto;
        display: grid;
        grid-template-columns: 520px;
        column-gap: 65px;
        /* background-color: aquamarine; */
        /* place-content: center; */

}
.footer-comapany-logo-image img{
    width: 40px;
    height: 40px;
} 
.footer-company-link-comapny-details{
    
    height: 36px;
    /* background-color: red; */
   
   
   }
   
.footer-company-link-comapny-legal{
    
    height: 66px;
    /* background-color: red; */
   
   
   }
.footer-company-link-comapny-details h6,
.footer-company-link-comapny-legal h6{
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;
    color: #EAECF0;
}
.footer-copyright-div div h5{
    
    margin: 0;
    padding: 0 0 0 0;
    font-size: 14px;
   
}
.footer-social-media-div{
    width: 120px;
    /* background-color: #0749cd; */
    height: 24px;
    display: flex;
    justify-content: space-between;
   
}
.footer-social-media-div img{
    width: 20px;
    height: 20px;
}
.footer-container{
    /* background-color:#141414; */
    height: 650px;
    display: grid;
    grid-template-rows: 520px  80px;
    /* background-color: aqua; */
   
}

}


/* sm */
@media (max-width:639px){
    .footer-details-row{
        grid-template-columns: 310px;
        column-gap: 65px;
        /* place-content: center; */
        margin-left: 0px;

}
.footer-copyright-div div h5{
    font-weight: 700;
    font-weight: 400;
    font-size: 10px;
    color:  #98A2B3;
    /* background-color: rgb(233, 193, 150); */
}
.footer-social-media-div{
    width: 120px;
    /* background-color: #0749cd; */
    height: 24px;
    display: flex;
    justify-content: space-between;
   
}
.footer-social-media-div img{
    width: 15px;
    height: 15px;
}

.footer-copyright-div h1 {
    margin: 120px 0 0 0;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.07);
   
}
.footer-copyright-div div{
    height: 24px;
    /* background-color: #6edc20; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 0;

}
.footer-social-media-div{
    width: 80px;
    /* background-color: #c3ad99; */
    gap: 8px;
    height: 24px;
    display: flex;
    justify-content: space-between;
   
}
.footer-copyright-div{
    height: 10px;
    /* background-color: aquamarine; */
    padding: 18px 50px 38px 50px;
    /* margin-top: 200px; */
}
.footer-container-details-row{
    /* background-color: rgb(19, 156, 154); */

    /* margin: 0 52px 0 52px; */
    border-bottom: 1px  #313131 solid;
}
.footer-container{
    /* background-color:#000000; */
    height: 550px;
    display: grid;
    grid-template-rows: 480px  80px;
    /* background-color: aqua; */
   


}


}

