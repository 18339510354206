.red-border:focus {
    border-color: black !important; 
    border-width: 2px;
    border-radius: 8px;
}
.red-border:focus + .flag-container .selected-flag {
    box-shadow: 0 0 0 2px red !important; /* Red box shadow on flag focus */
}

.custom-flags-select{
    height: 56px;
    width: 100%;
    border: 1px solid;
    border-color:#EAEAEA;
}
