.body-container-row{
    font-family: "Montserrat", sans-serif;
   
}
.section-1{
   height: 450px;
   position:relative;
   display: flex;
   justify-content: center;
   align-items: center;
   
}
.into-video{
    object-fit: cover;
    width: 100%;
    height: 450px;
   
 
  

}
.serach_and-name{
    position: absolute;
    /* background-color: red; */
  

   
}
.serach_and-name h1{
    font-weight: 700;
    font-size: 48px;
    color:#ffff;
    line-height: 90.84px;
    text-align: center;
   

}
.serach_and-name h6 {
 
    font-weight: 500;
    font-size: 18px;
    color:#ffff;
    text-align: center;
    line-height: 25.28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-container{
 width: 556px;
 height: 66px;
 border-radius:37px ;
 background-color: #ffff;
 display: flex;
 align-items: center;
 padding: 0 14px 0 14px;
 margin: 40px 0 0 0;
}
.seach-bar{
   
 width: 500px;
 height: 50px;
 border-radius:37px ; 
 border: none;
 outline: none;
 font-family: "Montserrat", sans-serif;
 font-size: 16px;
 font-weight: 500;


}


.seach-bar::placeholder{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #A4A4A4;

}
.search-btn{
    background-color:black;
    color: #ffff;
    border-radius: 90px;
    padding: 10px 15px;
    
    font-family: "Montserrat", sans-serif;
 
}

/* ------------------section 2------------------ */

.section{
    padding: 40px 0  0 0;
}
/* .section-2-container{
    height:auto;
    margin-bottom:30px;
   
} */



/* .section-2-container h1{
    font-weight: 700;
    font-size: 28px;
    line-height: 50px;
   
    
} */
.Offers-and-deals-container{
    font-family: "Montserrat", sans-serif;
    /* display: grid; */
   /* grid-template-columns: 1fr 1fr; */
    /* display:flex; */
    white-space: nowrap;
    width: auto;
    /* column-gap:32px ; */
    height: 236px;
    /* background-color: #3376A6; */
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
}
.Offers-and-deals-container-card-1{
    background-color: #C7DDE8 !important;
    /* background: red; */
  
    border-radius: 8px;
    position: relative;
    width: 450px;
    display: inline-block;
    height: 236px;

}
 
.Offers-and-deals-container-card-1-details{
    width: 250px;
    height: 165px;
    /* background-color: bisque; */
    position: absolute;
    top:35px;
    left:21px;

}
.Offers-and-deals-container-card-1-details h6{
    
   
    font-family: "Montserrat", sans-serif;
    margin: 0 0 10px 0;
    color: #3376A6;
    font-weight: 500;
    font-size: 12px;
    
}
.Offers-and-deals-container-card-1-details-2{
    width: 250px;
    height: 138px;
    /* background-color: #A4A4A4; */

}
.Offers-and-deals-container-card-1-details-2 h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #343434;
    /* background-color: #6f7073; */
    height: 52px;
    width: 100%;  
    white-space:wrap;


   

}
.Offers-and-deals-container-card-1-details-2 h1{
    font-weight: 700;
    font-size: 24px;
    line-height: 19.26px;
    color: #3763C2;

}
.Offers-and-deals-container-card-1-details-2 button{
    font-family: "Montserrat", sans-serif;
    width: 110px;
    height: 32px;
    border-radius: 36px;
    padding: 6px 12px;
    background-color: #1C274C;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.5px;
    text-align: center;
    color: #FFFFFF;

   

}
.Offers-and-deals-container-card-1-imges{
    /* position: absolute;
    top:0px;
    right:0; */
    /* background-color: #343434; */
    width: 160px;
    height: 100%;
    border-radius: 12.37px;
    margin-left: 290px;
    
    
}
.Offers-and-deals-container-card-1-imges img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0 0 0 0;
    transition: transform 0.3s ease;
    
}
.Offers-and-deals-container-card-1-imges:hover img{
    transform: scale(1.1);

}

/* ----------------card 2------------------------ */

.Offers-and-deals-container-card-2{
    background-color: #FFECD0;
    /* background-color: red; */
    border-radius: 8px;
    position: relative;
    width: 450px;
    display: inline-block;
    height: 236px;
}
.Offers-and-deals-container-card-2-details{
    width: 250px;
    height: 165px;
    /* background-color: bisque; */
    position: absolute;
    top:35px;
    left:21px;

}
.Offers-and-deals-container-card-2-details h6{
    
   
    font-family: "Montserrat", sans-serif;
    margin: 0 0 10px 0;
    color: #636363;
    font-weight: 500;
    font-size: 12px;
    
}
.Offers-and-deals-container-card-2-details-2{
    width: 250px;
    height: 138px;
    /* background-color: #A4A4A4; */

}
.Offers-and-deals-container-card-2-details-2 h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #343434;
    /* background-color: #b3b4b7; */
    height: 52px;
    /* text-overflow: ellipsis;
    overflow: hidden; */
    /* width: 250px; */
    /* white-space:wrap; */
    white-space:wrap;
    width: 100%;

}
.Offers-and-deals-container-card-2-details-2 h1{
    font-weight: 700;
    font-size: 24px;
    line-height: 19.26px;
    color: black;

}
.Offers-and-deals-container-card-2-details-2 button{
    font-family: "Montserrat", sans-serif;
    width: 110px;
    height: 32px;
    border-radius: 36px;
    padding: 6px 12px;
    background-color: #4E65B1;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.5px;
    text-align: center;
    color: #FFFFFF;

   

}
.Offers-and-deals-container-card-2-imges{
    /* position: absolute;
    top:0px;
    right:0; */
    /* background-color: #343434; */
    width: 160px;
    height: 100%;
    border-radius: 12.37px;
    margin-left: 290px;
    
}
.Offers-and-deals-container-card-2-imges img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
    margin: 0 0 0 0%;
    transition: transform 0.3s ease;


     
}
.Offers-and-deals-container-card-2-imges:hover img{
    transform: scale(1.1);

}











/* ------------ Discover our exclusive packages----------------------------- */

.section-2-row-2{
    margin: 52px 0 0 0;
    height: 449px;
  
    
}
.section-2-row-2 h1{
    font-weight: 700;
    font-size: 28px;
    line-height: 50px;
    display: inline-block;
    
   
    
}
/* .section-2-row-2-card{
    font-family: "Montserrat", sans-serif;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap:32px ;
    height: 397px;
    margin: 34px 0 0 0;
} */
.section-2-row-2-card{
    font-family: "Montserrat", sans-serif;
    /* display: flex;    */
    /* column-gap:25px ; */
    height: 397px;
    margin: 34px 0 0 0;
    scroll-behavior: smooth;
    
   
}
.section-2-row-2-card-1{
    /* background-color: #3376A6; */
    height: 395px;
    width: 250px !important;
    
}
.section-2-row-2-card-1-images{
    height: 272px;
    width: 100%;


}
.section-2-row-2-card-1-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 10px 0;
}
.section-2-row-2-card-1-details{
    height: 109px;
    /* background-color: #20c104; */
    width: 100%;
}
.section-2-row-2-card-1-details h2{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    line-height: 27px;
    color:  #343434;
    /* background-color: #d3cbcb; */
    height: 50px;
    white-space: wrap;
   
   
   
  
}
.section-2-row-2-card-1-days{
    width: auto;
    height: 8px;
    border-radius: 24px;
  
  
    color: white;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    font-weight: 400;
    line-height: 66.88px;
    margin: 10px 0 0 0 ;

}
.section-2-row-2-card-1-details h4{
 margin: 0;
 font-weight: 600;
 font-size: 14px;
 color: #333333;
 margin: 10px 0 0 0;
}



.section-2-row-2-card-2{
    /* background-color: #e03d21; */
    height: 395px;
   
  

}
.section-2-row-2-card-2-images{
    height: 272px;
    width: 100%;
   
 
}
.section-2-row-2-card-2-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 10px 0;
}
.section-2-row-2-card-2-days{
    width: 118px;
    height: 8px;
    border-radius: 24px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 66.88px;
    margin: 8px 0 0 0 ;

}




.section-2-row-2-card-3{
    /* background-color: #051f31; */
    height: 395px;
}
.section-2-row-2-card-3-images{
    height: 272px;
    width: 100%;
   
}
.section-2-row-2-card-3-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 10px 0;
 

}



.section-2-row-2-card-4{
    /* background-color: #e96d0f; */
    height: 395px;
}
.section-2-row-2-card-4-images{
    height: 272px;
    width: 100%;
    /* background-color: #e96d0f; */
}
.section-2-row-2-card-4-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 10px 0;
 

}

.scroll-button {
 
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
  }


/* ----------row 3---------------- */

.section-2-row-3{
    margin: 64px 0 0 0;
    height: 439px;
     
}
.section-2-row-3 h1{
    font-weight: 700;
    font-size: 28px;
    line-height: 50px;
    display: inline-block;
   
    
}
.section-2-row-3-card{
    font-family: "Montserrat", sans-serif;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap:32px ; */
    height: 397px;
    margin: 34px 0 0 0;
}
.section-2-row-3-card-1{
    /* background-color: #3376A6; */
    height: 395px;
    width: 250px !important;
}
.section-2-row-3-card-1-images{
    height: 272px;
    width: 100%;
    /* background-color: #e96d0f; */

}
.section-2-row-3-card-1-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 8px 0;
}
.section-2-row-3-card-1-details{
    height: auto;
    /* background-color: #709e67; */
    width: 250px;
    
    
}
.section-2-row-3-card-1-details h2{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    line-height: 27px;
    color:  #343434;
    white-space: wrap;
    height: 28px;
    /* background-color: #3763C2; */
  
}
.section-2-row-3-card-1-days{
    width: 55px;
    height: 10px;
    border-radius: 24px;
    padding: 0;
   
    color: #5F5F5F;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    font-weight: 400;
    line-height: 66.88px;
    margin: 8px 0 0 0 ;

}
.section-2-row-3-card-1-details h4{
 margin: 0;
 font-weight: 600;
 font-size: 14px;
 color: #333333;
 margin: 10px 0 0 0;
}



.section-2-row-3-card-2{
    /* background-color: #e03d21; */
    height: 395px;
    

}
.section-2-row-3-card-2-images{
    height: 272px;
    width: 100%;
   
 
}
.section-2-row-3-card-2-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 8px 0;
}




.section-2-row-3-card-3{
    /* background-color: #051f31; */
    height: 395px;
}
.section-2-row-3-card-3-images{
    height: 272px;
    width: 100%;
   
}
.section-2-row-3-card-3-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 8px 0;
 

}



.section-2-row-3-card-4{
    /* background-color: #e96d0f; */
    height: 395px;
}
.section-2-row-3-card-4-images{
    height: 272px;
    width: 100%;
    /* background-color: #e96d0f; */
}
.section-2-row-3-card-4-images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0 0 8px 0;
 

}

/* ------section-3-------- */

.section-3-container{
    height: 570px;
    /* background-color: aqua; */
    margin: 32px 0 0 0;
   
}
.section-3-container h1{
    font-weight: 700;
    font-size: 28px;
    line-height: 50px;
    color:#101828 ;
  
   
    
}

.section-3-row-1{
    height: 488px;
    margin: 0px 0 0 0;
  
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
   
}
.section-3-row-1-col-1{
    /* background-color: #3376A6; */
}
.section-3-row-1-col-1-image{
    height: 332px;
    /* background-color: #C7DDE8; */
    width: 100%;
}
.section-3-row-1-col-1-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.section-3-row-1-col-1-details{
    height: 124px;
    /* background-color: red; */
    margin-top: 28px;
}
.section-3-row-1-col-1-details h1{
    width: 540.5px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color:#101828 ;

    /* Line clamp styles */
    display: -webkit-box;           
    -webkit-line-clamp: 1;         
    -webkit-box-orient: vertical;   
    overflow: hidden;               
    text-overflow: ellipsis;   
    
}
.section-3-row-1-col-1-details h5{
    width: 580px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555555 ;
    margin: 2px 0 0 0 ;

    /* Line clamp styles */
    display: -webkit-box;           
    -webkit-line-clamp: 3;         
    -webkit-box-orient: vertical;   
    overflow: hidden;               
    text-overflow: ellipsis;   
    
}

.section-3-row-1-col-2{
    /* background-color: #7de472; */
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 30px;

}
.section-3-row-1-col-2-row-1{
    /* background-color: #343434; */
    display: grid;
   grid-template-columns: 320px  1fr;
    column-gap:24px ;
}

.section-3-row-1-col-2-row-1-image{
    width: 320px;
    height: 200px;
     /* background-color: #3763C2; */
}
.section-3-row-1-col-2-row-1-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.section-3-row-1-col-2-row-1-details{
 /* background-color: #5F5F5F; */
}
.section-3-row-1-col-2-row-1-details h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-top: 0;
    color:#101828 ;

     /* Line clamp styles */
     display: -webkit-box;           
     -webkit-line-clamp: 3;         
     -webkit-box-orient: vertical;   
     overflow: hidden;               
     text-overflow: ellipsis;    
}
.section-3-row-1-col-2-row-1-details h3{
  /* width: 190px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    color:#555555 ;

     /* Line clamp styles */
     display: -webkit-box;          
     -webkit-line-clamp: 5;         
     -webkit-box-orient: vertical;  
     overflow: hidden;               
     text-overflow: ellipsis;    
}

.section-3-row-1-col-2-row-2{
    /* background-color: #da0a0a; */
    display: grid;
    grid-template-columns: 320px  1fr;
     column-gap:24px ;
}
.section-3-row-1-col-2-row-2-image{
    /* background-color: #1C274C; */
   height: 200px;
  
}
.section-3-row-1-col-2-row-2-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.section-3-row-1-col-2-row-2-details { 
    /* background-color: #3376A6; */
}
.section-3-row-1-col-2-row-2-details h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-top: 0;
    color:#101828 ;

    display: -webkit-box;           
    -webkit-line-clamp: 3;         
    -webkit-box-orient: vertical;   
    overflow: hidden;               
    text-overflow: ellipsis;   
}
.section-3-row-1-col-2-row-2-details h3{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    color:#555555 ;

    display: -webkit-box;           
    -webkit-line-clamp: 5;         
    -webkit-box-orient: vertical;   
    overflow: hidden;               
    text-overflow: ellipsis;   
}




/* ------section 4 -------------- */
.section-4-container{
    
    /* background-color: #3763C2; */
   
    position: relative;
}
.beach-video{
    border-radius: 8px;
    
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@100..900&family=Lobster&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.section-4-container h2{
    font-family: "Inter", sans-serif;
    position: absolute;
    top:24px;
    right:24px;
    font-weight: 700;
    line-height: 38.73px;
    color: rgba(255,255,255,0.58);
   

}





/* xl */
@media (max-width:1279px){

    .section-3-row-1-col-1{       
        width: 500px;
        height:489px ;

    }
    .section-3-row-1-col-1-details h1{
        width: 500px;       
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color:#101828 ;
        
    }
    .section-3-row-1-col-1-details h5{
        width: 500px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;  
        color: #555555 ;
        margin: 2px 0 0 0 ;


        
    }
    .section-3-row-1-col-2{
        width: 448px;
        /* background-color: #2ec81d; */
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 30px;
        height: 488px;
    
    }
    .section-3-row-1-col-2-row-1{
        /* background-color: #f52222; */
        display: grid;
        height: 215px;
       grid-template-columns: 250px 1fr;
        column-gap:16px ;
        width: 448px;
    }
    .section-3-row-1-col-2-row-1-image{
        width: 250px;
         /* background-color: #3763C2; */
    }
    .section-3-row-1-col-2-row-1-details{
        /* background-color: #5F5F5F; */
       }
       .section-3-row-1-col-2-row-1-details h2{
           font-weight: 600;
           font-size: 16px;
           line-height: 28px;
           margin-top: 0;
           color:#101828 ;

           display: -webkit-box;           
           -webkit-line-clamp: 2;         
           -webkit-box-orient: vertical;   
           overflow: hidden;               
           text-overflow: ellipsis;  
       }
       .section-3-row-1-col-2-row-1-details h3{
         /* width: 190px; */
           font-weight: 400;
           font-size: 12px;
           line-height: 20px;
           margin-top: 0;
           color:#555555 ;

           display: -webkit-box;           
           -webkit-line-clamp: 7;         
           -webkit-box-orient: vertical;   
           overflow: hidden;               
           text-overflow: ellipsis;  
       }
       .section-3-row-1-col-2-row-2{
        /* background-color: #da0a0a; */
        height: 229px;
        grid-template-columns: 250px 1fr;
         column-gap:16px ;
         width: 448px;

    }
    .section-3-row-1-col-2-row-2-image{
        /* background-color: #1C274C; */
       height: 200px;
    
       
    }
    .section-3-row-1-col-2-row-2-image img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    .section-3-row-1-col-2-row-2-details { 
        /* background-color: #3376A6; */
    }
    .section-3-row-1-col-2-row-2-details h2{
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        margin-top: 0;
        color:#101828 ;

        display: -webkit-box;           
        -webkit-line-clamp: 2;         
        -webkit-box-orient: vertical;   
        overflow: hidden;               
        text-overflow: ellipsis;  
    }
    .section-3-row-1-col-2-row-2-details h3{
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        margin-top: 0;
        color:#555555 ;

        display: -webkit-box;           
        -webkit-line-clamp: 7;         
        -webkit-box-orient: vertical;   
        overflow: hidden;               
        text-overflow: ellipsis;  
    }
    
 
}

/* lg */
@media (max-width:1023px){

    .Offers-and-deals-container{
      
        height: 200px;
        column-gap:15px;
        
    }
    .Offers-and-deals-container-card-1{
        width: 400px;
        /* background-color: rgb(13, 255, 0); */

    }
    .Offers-and-deals-container-card-2{
        width: 400px ;
        /* background-color: #40a633; */

    }

    .Offers-and-deals-container-card-1-details{
        width: 190px;
        /* background-color: red; */
        height: 150px;
        position: absolute;
        top:30px;
    
    
    }
   
    
    .Offers-and-deals-container-card-1-imges{
        width: 150px; 
        margin-left: 250px;
    }
    
    .Offers-and-deals-container-card-1-details-2,
    .Offers-and-deals-container-card-2-details-2{
        width: 190px;
        height: 120px;
        /* background-color: #3376A6; */
       
    
    }
    .Offers-and-deals-container-card-1-details-2 h2{
       
        font-size: 14px;
        line-height: 25px;
        /* background-color: red; */
        white-space: wrap;
        height: 56px;

       
    
    }
    .Offers-and-deals-container-card-1-details-2 h1{
       
        font-size: 20px;
        line-height: 5.26px;
       
    
    }
    .Offers-and-deals-container-card-1-details-2 button{
        
        width: 100px;
        height: 30px;
        font-size: 12px;
        line-height: 15.5px;
       

    }


    
    .Offers-and-deals-container-card-2-details{
        width: 190px;
        /* background-color: red; */
        height: 150px;
        position: absolute;
        top:30px;
    
    
    }
    
    .Offers-and-deals-container-card-2-imges{
        width: 150px; 
        margin-left: 250px;
    }
    
    .Offers-and-deals-container-card-2-details-2{
        width: 190px;
        height: 120px;
    
    
    }
    .Offers-and-deals-container-card-2-details-2 h2{
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        white-space: wrap;
        height: 54px;
        /* background-color: #3763C2; */
      
    
    }
    .Offers-and-deals-container-card-2-details-2 h1{
        
        font-size: 20px;
        line-height: 5.26px;
       
    
    }
    .Offers-and-deals-container-card-2-details-2 button{
        font-family: "Montserrat", sans-serif;
        width: 100px;
        height: 30px;
        border-radius: 36px;
        padding: 6px 12px;
    
        border: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 15.5px;
        text-align: center;
        color: #FFFFFF;   
    
    }

     /* ---section-3----- */
     /* .section-2-row-2-card{
        font-family: "Montserrat", sans-serif;
        display: grid;
        grid-template-columns:  300px 1fr 1fr 1fr ;
        column-gap:32px ;
        height: 397px;
        margin: 34px 0 0 0;
        background-color: #40c237;
       
    } */
/* --------section 3-------- */
.section-3-container{
    height: auto;
}
.section-3-row-1{
    height: auto;
    margin: 0px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    padding-bottom: 25px;
   
}
    .section-3-row-1-col-1{       
        width: 700px;
        height:439px ;
        margin-bottom: 25px;
        /* background-color: red; */
    }
   
    .section-3-row-1-col-1-details h1{
        width: 700px;       
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color:#101828 ;
       
    }
    .section-3-row-1-col-1-details h5{
        width: 700px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;  
        color: #555555 ;
        margin: 2px 0 0 0 ;

        display: -webkit-box;           
        -webkit-line-clamp: 2;         
        -webkit-box-orient: vertical;   
        overflow: hidden;               
        text-overflow: ellipsis; 

        
    }
    .section-3-row-1-col-2{
        width: 700px;
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 0px;
        height: auto;
        
        
    
    }
    .section-3-row-1-col-2-row-1{     
        height: 435px;  
        column-gap:16px ;
        width: 700px;
        grid-template-columns: 700px;
        margin-bottom: 30px;
       
    }
 
    .section-3-row-1-col-2-row-1-image{
        width: 700px;
        height: 332px;
      
    }
    .section-3-row-1-col-2-row-1-details{
      
      
       }
       .section-3-row-1-col-2-row-1-details h2{
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;
            margin-top: 0;
            color:#101828 ;
            margin-top: 25px;

                /* Line clamp styles */
            display: -webkit-box;           
            -webkit-line-clamp: 1;         
            -webkit-box-orient: vertical;   
            overflow: hidden;               
            text-overflow: ellipsis;   
       }
       .section-3-row-1-col-2-row-1-details h3{
        width: 700px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px; 
        color: #555555 ;
        margin: 2px 0 0 0 ;

             /* Line clamp styles */
     display: -webkit-box;           
     -webkit-line-clamp: 2;         
     -webkit-box-orient: vertical;   
     overflow: hidden;               
     text-overflow: ellipsis;   
       }

       .section-3-row-1-col-2-row-2{
        height: 229px;
         column-gap:16px ;
         width: 700px;
         grid-template-columns: 700px;
    }

    .section-3-row-1-col-2-row-2-image{
        /* background-color: #1C274C; */
        width: 700px;
        height: 332px;
       
    }

    .section-3-row-1-col-2-row-2-image img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    .section-3-row-1-col-2-row-2-details { 
        /* background-color: #3376A6; */
    }

    .section-3-row-1-col-2-row-2-details h2{
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        margin-top: 0;
        color:#101828 ;
        margin-top: 25px;

        display: -webkit-box;           
        -webkit-line-clamp: 1;         
        -webkit-box-orient: vertical;   
        overflow: hidden;               
        text-overflow: ellipsis; 
    }

    .section-3-row-1-col-2-row-2-details h3{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;  
        margin-top: 0;
        color:#555555 ;

        display: -webkit-box;           
        -webkit-line-clamp: 2;         
        -webkit-box-orient: vertical;   
        overflow: hidden;               
        text-overflow: ellipsis; 
    }

}


@media (max-width:767px){

    .Offers-and-deals-container{
      
        height: 190px;
        column-gap:15px;
        
    }
    .Offers-and-deals-container-card-1,
    .Offers-and-deals-container-card-2{
        width: 400px;
        /* background-color: red; */
        height: 226px;

    }
   
    .Offers-and-deals-container-card-1-details,
    .Offers-and-deals-container-card-2-details{
       
        top:15px;
        /* height: 100px; */
    }

    .Offers-and-deals-container-card-1-imges,
    .Offers-and-deals-container-card-2-imges{
        width: 120px; 
        margin-left: 280px;
        
    }
    
    
    .Offers-and-deals-container-card-1-details-2{
        width: 148px;
        height: 120px;
   
    
    
    }

    .Offers-and-deals-container-card-1-details-2 h2,
    .Offers-and-deals-container-card-2-details-2 h2{
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        height: 60px;
        /* background-color: #3485d7; */
        width: 240px;
        height: 42px;
        margin-bottom: 30px;
       
      
    
    }

    .Offers-and-deals-container-card-1-details-2 h1,
    .Offers-and-deals-container-card-2-details-2 h1
    {
        
        font-size: 15px;
        line-height: 1.26px;
       
    
    }
    
    
    
    
    .Offers-and-deals-container-card-2-details-2{
        width: 148px;
        height: 120px;
      
    
    
    }
    .Offers-and-deals-container-card-1-details-2 button,
    .Offers-and-deals-container-card-2-details-2 button{
       
        width: 70px;
        height: 25px;
        border-radius: 36px;
        padding: 5px 12px;
        font-size: 8px;
        line-height: 15.5px;
       

    }
    .section-2-row-2 h1,
    .section-2-row-3 h1{
        font-size: 20px;
        line-height: 35.5px;
       
        
    
    }
    .section-3-heading h1{
        font-size: 20px;
        line-height: 35.5px;
    
    }
    

    /* ---section---3 */

    .section-3-row-1{
        height: auto;
        margin: 0px 0 0 0;
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 15px;
        padding-bottom: 25px;
       
       
    }
        .section-3-row-1-col-1{       
            width: 550px;
            height:435px ;
            margin-bottom: 0px !important;
          
        }
        .section-3-row-1-col-1-details{
            height:93px ;
        }

        .section-3-row-1-col-1-details h1{
            width: 550px;       
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color:#101828 ;
       
           
        }
        .section-3-row-1-col-1-details h5{
            width: 550px;
            font-weight: 400;
            font-size: 13px;
            line-height: 23px;  
            color: #555555 ;
            margin: 2px 0 0 0 ;
       
    
            
        }
        .section-3-row-1-col-2{
            width: 550px;
            display: grid;
            grid-template-rows: 1fr 1fr;
            row-gap: 20px;
            height: auto;
            margin-top: 20px;
        
            
            
        
        }
        .section-3-row-1-col-2-row-1{     
            height: 435px;  
            column-gap:16px ;
            width: 550px;
            grid-template-columns: 550px;
            margin-bottom: 30px;
           
        }
     
        .section-3-row-1-col-2-row-1-image{
            width: 550px;
            height: 332px;
          
        }
       
           .section-3-row-1-col-2-row-1-details h2{
               font-weight: 600;
               font-size: 20px;
               line-height: 30px;
               margin-top: 0;
               color:#101828 ;
               margin-top: 25px;
           }
           .section-3-row-1-col-2-row-1-details h3{
            width: 550px;
            font-weight: 400;
            font-size: 13px;
            line-height: 23px;
            color: #555555 ;
            margin: 2px 0 0 0 ;
           }
    
           .section-3-row-1-col-2-row-2{
            height: 229px;
             column-gap:16px ;
             width: 550px;
             grid-template-columns: 550px;
        }
    
        .section-3-row-1-col-2-row-2-image{
            /* background-color: #1C274C; */
            width: 550px;
            height: 332px;
           
        }
    
        .section-3-row-1-col-2-row-2-image img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    
        .section-3-row-1-col-2-row-2-details { 
            /* background-color: #3376A6; */
        }
    
        .section-3-row-1-col-2-row-2-details h2{
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            margin-top: 0;
            color:#101828 ;
            margin-top: 25px;
        }
    
        .section-3-row-1-col-2-row-2-details h3{
            font-weight: 400;
            font-size: 13px;
            line-height: 23px; 
            margin-top: 0;
            color:#555555 ;
        }
   

}


/* sm */
@media (max-width:630px){
    

    /* .search-container{
      
        padding: 0 6px 0 14px;
      
       }

    .serach_and-name h1{
        font-size: 25px;
        line-height: 80.28px;
    }
   
    .serach_and-name h6{
        font-size: 11px;
        line-height: 1.28px;

    }
    .search-container{
        
        width: 290px;
        height: 40px;
    
    }
    .seach-bar{
   
        width: 150gpx;
        height: 40px;
       
        font-size: 12px;
        font-weight: 400;
       

       }
       .seach-bar::placeholder{
     
        font-size: 10px;
        font-weight: 400;
       
     
    
    }

    .search-btn{
        background-color:black;
        color: #ffff;
        border-radius: 90px;
        padding: 1px 1px;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
     
    }




.search-btn{
    padding: 8px 10px;
    
} */

/* .Offers-and-deals-container{
   
    margin-right: 12px;
}

.Offers-and-deals-container-card-1,
.Offers-and-deals-container-card-2
{
    width: 350px;

}

.Offers-and-deals-container-card-1-imges,
.Offers-and-deals-container-card-2-imges{
    width: 120px; 
}

.Offers-and-deals-container-card-1-details-2 button,
.Offers-and-deals-container-card-2-details-2 button{
   
    width: 80px;
    height: 25px;
    border-radius: 36px;
    padding: 6px 12px;
    font-size: 10px;
    line-height: 15.5px;
   

} */

.section-2-row-2{
    margin: 30px 0 0 0;
   
  
    
}

.section-2-row-2 h1,
.section-2-row-3 h1{
    font-size: 17px;
    line-height: 25.5px;
    /* background-color: lightblue; */
    
   
    

}
.section-3-heading h1{
    font-size: 17px;
    line-height: 25.5px;
    /* background-color: #c6c7cb; */

}

/* ---section---3 */

.section-3-row-1{
  
    margin: 0px 0 0 0;
   
    display: grid;
    grid-template-columns: 1fr ;
    column-gap: 15px;
    padding-bottom: 25px;
    width: 100%;
}

.section-3-row-1-col-1{
   
    width:100% !important;
    height:auto ;
    margin-bottom: 0px;
    
}
.section-3-row-1-col-1-image{
    height: 232px;
    width: 100%;
}
.section-3-row-1-col-1-image img{
    object-fit: cover;
    width: 100%;
    border-radius: 8px;

}
.section-3-row-1-col-1-details{
    
   
    margin-top: 28px;
    height:auto;
}
.section-3-row-1-col-1-details h1{
    width:100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 25.5px;
    color:#101828 ;
    /* background-color: #3376A6; */
   
}
.section-3-row-1-col-1-details h5{
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 23px; 
   
    color: #555555 ;
    margin: 2px 0 0 0 ;
    
}
.section-3-row-1-col-2{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 1px;
    height: auto;
    margin-top: 20px;

}
.section-3-row-1-col-2-row-1{     
    height:auto;
    column-gap:16px ;
    width: 100%;
    grid-template-columns: 100%;
    margin-bottom: 20px;
   
   
}

.section-3-row-1-col-2-row-1-image{
    width: 100%;
    height: 232px;

   
  
}
.section-3-row-1-col-2-row-1-details{
   
    width: 100%;
    margin-top: 28px;
    height: auto;
    
  
  
   }
   .section-3-row-1-col-2-row-1-details h2{
       font-weight: 600;
       font-size: 16px;
       line-height: 25.5px;
       color:#101828 ;
       margin-top: 0px;
       width: 100%;
      
   }
   .section-3-row-1-col-2-row-1-details h3{
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    color: #555555 ;
    margin: 2px 0 0 0 ;
  

   }

   .section-3-row-1-col-2-row-2{
     height: auto;
     column-gap:16px ;
     width: 100%;
     grid-template-columns: 100%;
}

.section-3-row-1-col-2-row-2-image{
    /* background-color: #1C274C; */
    width: 100%;
    height: 240px;
   
}

.section-3-row-1-col-2-row-2-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.section-3-row-1-col-2-row-2-details { 
    /* background-color: #3376A6; */
    height: auto;
  
}

.section-3-row-1-col-2-row-2-details h2{
    font-weight: 600;
    font-size: 16px;
    line-height: 25.5px;
    margin-top: 0;
    color:#101828 ;
    margin-top: 25px;
    width: 100%;
}

.section-3-row-1-col-2-row-2-details h3{
    font-weight: 400;
    font-size: 13px;
    line-height: 23px; 
    margin-top: 0;
    color:#555555 ;
    width: 100%;
}





}/* sm */
@media (max-width:640px){
    

    .search-container{
      
        padding: 0 6px 0 14px;
      
       }
    /* .serach_and-name{
        background-color: #da470d;
    }
    */

    .serach_and-name h1{
        font-size: 25px;
        line-height: 80.28px;
    }
   
    .serach_and-name h6{
        font-size: 11px;
        line-height: 1.28px;

    }
    .search-container{
        
        width: 290px;
        height: 40px;
    
    }
    .seach-bar{
   
        width: 150gpx;
        height: 40px;
       
        font-size: 12px;
        font-weight: 400;
       

       }
       .seach-bar::placeholder{
     
        font-size: 10px;
        font-weight: 400;
       
     
    
    }

    .search-btn{
        background-color:black;
        color: #ffff;
        border-radius: 90px;
        padding: 1px 1px;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
    }




.search-btn{
    padding: 8px 10px;
    
}
.section-1{
    height: 300px; 
 }
.into-video{
    height: 300px;

}
.Offers-and-deals-container-card-1,
.Offers-and-deals-container-card-2{
    /* background-color: #09db06 !important; */
    /* background-color: red; */

    height: 170px;
    width: 280px;

}


.Offers-and-deals-container-card-1-details,
.Offers-and-deals-container-card-2-details{
   
    width: 135px;
    /* background-color: red; */
    /* height: 100px; */
}

.Offers-and-deals-container-card-1-details-2,
.Offers-and-deals-container-card-2-details-2{
   
    height: 110px;
    /* background-color: #460be9; */
    width: 135px;

}

    

.Offers-and-deals-container-card-1-details-2 h2,
.Offers-and-deals-container-card-2-details-2 h2{

    /* background-color: #3485d7; */
    width: 135px;
 
}


.Offers-and-deals-container-card-1-details-2 h1,
.Offers-and-deals-container-card-2-details-2 h1{
 
    line-height: 1.26px;
    margin-top: -12px;
  

}

.Offers-and-deals-container-card-1-imges,
.Offers-and-deals-container-card-2-imges{
    width: 110px; 
    margin-left: 170px;
    
}




}




