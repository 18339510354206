.offer-row{
    background-image: linear-gradient( to right, #AC4A33  ,  #FF967D  );
    padding: 0 0 0 0;
    height: auto;

}
.offer-container{
    font-family: "Montserrat", sans-serif;
    color: #ffff;
    margin-left: 10%;
    margin-right:10%;
 
}





   

