/* Change the color of the selected date to orange */
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--selected:focus {
    background-color: #DE6A29 !important;
    border-color: #DE6A29 !important;
    color: white !important;
}


/* Change the color of the highlighted dates to orange */
.react-datepicker__day--highlighted,
.react-datepicker__day--highlighted:hover,
.react-datepicker__day--highlighted:focus {
    background-color: #DE6A29 !important;
    color: white !important;
}
  