@media(max-width:900px){
    .nav-row{
        padding: 0 8px 0 8px;
    }
}

.nav-row{
    font-family: "Montserrat", sans-serif;
    height: 75px;
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 128px 0 128px;
    border-bottom: solid 1px;
    border-color:#D4D4D4;
    
}
.name-logo{
/* background-color: black; */
display: flex;
flex-direction: row;

height: 55px;



}
.name-logo img{
  width: 54px;
  height: 55px;

}
.company-name{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 55.88px;
    margin: 0 0 0 18px;
    

    
}

.nav-link{
 /* background-color: brown; */
 flex:'1';
 display: flex;
 justify-content: space-between;
 width:267px ;
 /* margin-left: -12px; */
 text-decoration: none;
 font-weight: 500;
 font-size: 16px;


}
.nav-link-btn {
   
    color:black;
    text-decoration: none;

}
.login-sign_up{
/* background-color: blue; */
display: flex;
align-items: center;

}
.login-sign_up img{
    width: 32px;
    height: 32px;
}
.login-signup-btn{
   
    height: 45px;
    border-radius: 32px;
    border: 1px #D4D4D4 solid;
    margin-left:2%;
    padding-left:2%;
    padding-right:2%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

}

.login-signup-btn-link{
    color:black;
}



.offer-row{
    background-image: linear-gradient( to right, #AC4A33  ,  #FF967D  );
    height:52px ;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 0 0 0 ;
}


.full-size-modal .ant-modal-content {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.full-size-modal .ant-modal-body {
  height: calc(100vh - 55px); /* Adjust this value based on the header and footer height */
  overflow: auto;
  margin: 0;
  padding: 0;
}

.full-size-modal .ant-modal-header,
.full-size-modal .ant-modal-footer {
  margin: 0;
  padding: 0;
}

/* ----- login modal design------------------------------------- */
/* .ant-modal-content {
  height: 38vh;
 
  margin: 0;
  padding: 0;
  box-shadow: none;
  background: none;

}

.full-size-modal .ant-modal-body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

 .ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
} */




  