.activity-card{
    display: flex;
    flex-direction: column;
    min-height: 381px;
}

.activity-card-img{
    height: 272px;

}

.activity-card-name{
   
    font-weight:600;
    text-align: left;
    
}

.activity-card-HRS{
    font-size: 12px;
    font-weight: 400;
    color: #5F5F5F;
}

.activity-card-rate {
    
    font-weight: 600;

}

.activity-card-span{
  
    font-weight: 500;
    line-height: 34px;  
}